// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-radar {
  /* from leaflet-control-layers */
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;

  padding: 5px;
  height: 48px;
}

.leaflet-radar .leaflet-radar-timestamp {
  text-align: center;
}

.leaflet-radar-toggle {
  display: flex;
  align-items: left;
}

.leaflet-radar-toggle input {
  display: inherit !important;
  min-width: 10px;
  width: 18px;
}

.leaflet-radar-toggle span {
  box-sizing: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/leaftlet.radar.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,oCAAoC;EACpC,4BAA4B;;EAE5B,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".leaflet-radar {\n  /* from leaflet-control-layers */\n  border-radius: 5px;\n  background: #fff;\n  border: 2px solid rgba(0, 0, 0, 0.2);\n  background-clip: padding-box;\n\n  padding: 5px;\n  height: 48px;\n}\n\n.leaflet-radar .leaflet-radar-timestamp {\n  text-align: center;\n}\n\n.leaflet-radar-toggle {\n  display: flex;\n  align-items: left;\n}\n\n.leaflet-radar-toggle input {\n  display: inherit !important;\n  min-width: 10px;\n  width: 18px;\n}\n\n.leaflet-radar-toggle span {\n  box-sizing: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
