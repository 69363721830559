import legend_has_acars_url from "./images/legend-has-acars.svg";
import legend_has_acars_alert_unread_url from "./images/legend-has-acars-alert-unread.svg";
import legend_has_acars_alert_read_url from "./images/legend-has-acars-alert-read.svg";
import legend_with_acars_unread_url from "./images/legend-with-acars-unread.svg";
import legend_without_acars_url from "./images/legend-without-acars.svg";
import acars_alert_url from "./sounds/alert.mp3";
import safari_image_url from "./images/safari.png";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faPlaneSlash } from "@fortawesome/free-solid-svg-icons/faPlaneSlash";
import { faCommentAlt as faCommentAltSolid } from "@fortawesome/free-solid-svg-icons/faCommentAlt";
import { faCommentAlt as faCommentAltRegular } from "@fortawesome/free-regular-svg-icons/faCommentAlt";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons/faToggleOn";
import { faToggleOff } from "@fortawesome/free-solid-svg-icons/faToggleOff";
import { faSquare as faSquareSolid } from "@fortawesome/free-solid-svg-icons/faSquare";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons/faSquare";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
library.add(faCheck);
library.add(faPlane);
library.add(faPlaneSlash);
library.add(faCommentAltSolid);
library.add(faCommentAltRegular);
library.add(faToggleOn);
library.add(faToggleOff);
library.add(faSquareSolid);
library.add(faSquareRegular);
library.add(faArrowUp);
library.add(faArrowDown);
library.add(faPlus);
library.add(faMinus);
dom.watch();
export let images = {
    acars_hub_logo: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="764px" height="577px" viewBox="0 0 764 577" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
     <!-- Generated by Pixelmator Pro 2.0.8 -->
     <path id="Path" d="M-683 -293 L1446.84 -293 1446.84 869.44 -683 869.44 Z" fill="none" stroke="none"/>
     <g id="group">
      <g id="group-1">
       <g id="group-2">
        <path id="Path-1" d="M31.99 472.33 L48.75 472.33 80.02 574.48 65.72 574.48 58.19 547.87 22.41 547.87 15.02 574.48 0.72 574.48 Z M40.34 484.5 L25.77 536.77 54.92 536.77 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        <path id="Path-2" d="M162.97 570.85 C159.46 572.72 155.85 574.12 152.16 575.06 148.46 575.99 144.54 576.46 140.39 576.46 127.3 576.46 117.14 571.83 109.91 562.57 102.68 553.31 99.07 540.29 99.07 523.5 99.07 506.81 102.71 493.8 109.98 484.47 117.25 475.14 127.39 470.48 140.39 470.48 144.54 470.48 148.46 470.95 152.16 471.88 155.85 472.82 159.46 474.22 162.97 476.09 L162.97 490.25 C159.59 487.47 155.97 485.35 152.09 483.89 148.21 482.43 144.31 481.7 140.39 481.7 131.4 481.7 124.68 485.17 120.21 492.1 115.74 499.03 113.51 509.5 113.51 523.5 113.51 537.46 115.74 547.9 120.21 554.84 124.68 561.77 131.41 565.24 140.39 565.24 144.4 565.24 148.34 564.51 152.19 563.05 156.04 561.59 159.64 559.47 162.96 556.69 L162.96 570.85 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        <path id="Path-3" d="M214.72 472.33 L231.48 472.33 262.75 574.48 248.45 574.48 240.92 547.87 205.14 547.87 197.75 574.48 183.45 574.48 Z M223.07 484.5 L208.5 536.77 237.65 536.77 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        <path id="Path-4" d="M324.28 526.24 C327.84 527.15 330.87 528.87 333.38 531.41 335.89 533.94 339.01 538.99 342.75 546.56 L356.64 574.47 341.79 574.47 329.61 548.68 C326.1 541.34 322.94 536.6 320.13 534.48 317.32 532.36 313.66 531.3 309.15 531.3 L295.95 531.3 295.95 574.47 282.06 574.47 282.06 472.33 310.52 472.33 C321.74 472.33 330.34 474.86 336.31 479.92 342.28 484.98 345.27 492.3 345.27 501.88 345.27 508.63 343.43 514.14 339.76 518.4 336.11 522.67 330.94 525.28 324.28 526.24 Z M295.96 483.68 L295.96 519.94 311.08 519.94 C317.69 519.94 322.62 518.46 325.86 515.49 329.1 512.53 330.72 507.99 330.72 501.88 330.72 496 329 491.49 325.55 488.37 322.11 485.25 317.1 483.68 310.53 483.68 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        <path id="Path-5" d="M432.89 475.81 L432.89 489.84 C428.69 487.15 424.48 485.12 420.27 483.75 416.05 482.38 411.8 481.7 407.51 481.7 400.99 481.7 395.83 483.22 392.05 486.25 388.26 489.28 386.37 493.38 386.37 498.53 386.37 503.05 387.61 506.49 390.1 508.86 392.58 511.23 397.23 513.22 404.02 514.81 L411.27 516.45 C420.85 518.69 427.83 522.2 432.21 526.99 436.59 531.78 438.78 538.3 438.78 546.56 438.78 556.27 435.77 563.69 429.75 568.8 423.73 573.91 414.97 576.46 403.48 576.46 398.69 576.46 393.88 575.95 389.04 574.92 384.21 573.89 379.35 572.35 374.47 570.3 L374.47 555.59 C379.71 558.92 384.67 561.36 389.35 562.91 394.02 564.46 398.73 565.24 403.48 565.24 410.46 565.24 415.89 563.68 419.76 560.55 423.64 557.43 425.58 553.06 425.58 547.45 425.58 542.34 424.25 538.44 421.58 535.75 418.91 533.06 414.27 530.98 407.66 529.52 L400.27 527.81 C390.78 525.67 383.89 522.43 379.61 518.09 375.32 513.76 373.18 507.94 373.18 500.64 373.18 491.52 376.25 484.21 382.38 478.71 388.51 473.21 396.67 470.47 406.84 470.47 410.76 470.47 414.89 470.92 419.22 471.8 423.54 472.7 428.1 474.04 432.89 475.81 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
       </g>
       <g id="group-3">
        <g id="group-4">
         <path id="Path-6" d="M511.97 472.33 L525.86 472.33 525.86 511.46 563.69 511.46 563.69 472.33 577.58 472.33 577.58 574.48 563.69 574.48 563.69 523.1 525.86 523.1 525.86 574.48 511.97 574.48 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
         <path id="Path-7" d="M604.02 535.27 L604.02 472.33 617.91 472.33 617.91 541.57 C617.91 546.54 618.05 550.09 618.32 552.21 618.59 554.33 619.07 555.96 619.76 557.1 621.22 559.79 623.33 561.82 626.09 563.19 628.85 564.56 632.19 565.24 636.11 565.24 640.08 565.24 643.43 564.56 646.17 563.19 648.91 561.82 651.03 559.79 652.53 557.1 653.21 555.96 653.69 554.34 653.97 552.24 654.24 550.14 654.38 546.63 654.38 541.7 L654.38 472.32 668.2 472.32 668.2 535.26 C668.2 545.71 667.55 553.13 666.25 557.53 664.95 561.93 662.7 565.57 659.51 568.44 656.5 571.13 653.06 573.14 649.18 574.46 645.3 575.78 640.95 576.44 636.11 576.44 631.32 576.44 626.99 575.78 623.11 574.46 619.23 573.14 615.77 571.13 612.71 568.44 609.56 565.61 607.33 561.95 606.01 557.46 604.68 552.98 604.02 545.58 604.02 535.27 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
         <path id="Path-8" d="M696.69 472.33 L726.93 472.33 C737.37 472.33 745.42 474.59 751.08 479.1 756.73 483.62 759.56 490 759.56 498.26 759.56 502.68 758.07 506.7 755.08 510.3 752.09 513.9 747.61 516.16 741.64 517.07 748.35 518.07 753.6 520.94 757.41 525.66 761.22 530.38 763.12 537.3 763.12 546.42 763.12 555.68 760.09 562.67 754.02 567.39 747.95 572.11 738.92 574.47 726.93 574.47 L696.69 574.47 Z M710.57 483.68 L710.57 511.73 726.65 511.73 C733.31 511.73 738.13 510.45 741.12 507.9 744.11 505.35 745.6 502.13 745.6 498.25 745.6 493.1 744.13 489.38 741.19 487.1 738.25 484.82 733.4 483.68 726.65 483.68 Z M710.57 522.96 L710.57 563.12 726.92 563.12 C734.95 563.12 740.67 561.72 744.09 558.91 747.51 556.11 749.22 551.49 749.22 545.06 749.22 536.58 747.42 530.77 743.81 527.65 740.21 524.53 734.57 522.96 726.91 522.96 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        </g>
       </g>
       <g id="group-5">
        <g id="group-6">
         <path id="Path-9" d="M127.79 385.75 C114.74 372.7 97.42 366.44 80.28 366.99 L80.35 374.17 C95.62 373.64 111.08 379.19 122.72 390.83 134.37 402.48 139.91 417.93 139.38 433.2 L146.56 433.27 C147.1 416.12 140.85 398.81 127.79 385.75 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        </g>
        <g id="group-7">
         <path id="Path-10" d="M116.51 397.04 C106.6 387.13 93.43 382.45 80.43 382.97 L80.5 390.15 C91.63 389.63 102.95 393.64 111.43 402.12 119.92 410.61 123.92 421.93 123.4 433.05 L130.58 433.12 C131.1 420.12 126.42 406.95 116.51 397.04 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        </g>
        <g id="group-8">
         <path id="Path-11" d="M105.22 408.32 C98.46 401.56 89.43 398.42 80.57 398.91 L80.64 406.11 C87.64 405.64 94.8 408.06 100.14 413.4 105.49 418.75 107.92 425.92 107.44 432.91 L114.64 432.98 C115.12 424.11 111.98 415.08 105.22 408.32 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
        </g>
        <path id="Path-12" d="M109.35 485.29 C87.82 488.25 65.22 481.45 48.67 464.9 32.11 448.34 25.31 425.74 28.27 404.21 L66.26 442.2 79 429.46 C78.33 427.01 78.95 424.29 80.88 422.36 83.72 419.52 88.35 419.52 91.2 422.36 94.06 425.22 94.06 429.84 91.21 432.69 89.28 434.62 86.54 435.24 84.1 434.56 L71.36 447.3 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
       </g>
      </g>
      <g id="group-9">
       <g id="group-10">
        <path id="Path-13" d="M604.96 219.96 L571.49 221.82 577.17 227.42 568.2 234.93 550.59 249.67 523.72 272.17 501.43 290.83 381.92 390.87 262.41 290.83 240.1 272.17 213.23 249.67 195.62 234.92 186.65 227.43 192.33 221.83 158.86 219.97 149.77 228.94 150.05 229.17 167.67 243.92 381.91 423.24 596.14 243.92 613.75 229.18 614.04 228.93 Z M381.92 34.86 L559.56 210.07 593.02 208.21 381.91 -0.01 170.8 208.2 204.27 210.06 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
       </g>
       <path id="Path-14" d="M687.36 215.37 L687.36 219.87 613.76 229.18 568.21 234.94 507.16 242.65 C512.02 246.95 514.89 253.45 514.17 260.58 513.19 270.29 505.36 278.2 495.66 279.29 482.97 280.69 472.25 270.8 472.25 258.41 472.25 254.03 473.58 249.97 475.86 246.6 L424.13 253.13 C412.06 265.56 398.3 272.76 389.86 276.36 384.77 278.53 379.05 278.53 373.97 276.36 365.53 272.76 351.75 265.56 339.7 253.13 L287.97 246.6 C290.64 250.54 292 255.44 291.46 260.66 290.47 270.34 282.65 278.22 272.97 279.29 260.29 280.69 249.56 270.8 249.56 258.41 249.56 252.13 252.32 246.49 256.68 242.65 L195.61 234.92 150.04 229.16 76.47 219.87 76.47 215.37 158.85 219.96 192.32 221.82 340.81 230.09 C340.81 230.09 340.86 228.38 341.28 225.62 L269.65 211.61 269.65 208.52 345.73 210.46 C350.23 200.42 358.89 189.48 375.46 185.48 L381.91 118.38 388.36 185.48 C404.93 189.48 413.59 200.42 418.09 210.46 L494.17 208.52 494.17 211.61 422.54 225.62 C422.96 228.38 423.01 230.09 423.01 230.09 L571.48 221.82 604.95 219.96 Z" fill="#1496bb" fill-opacity="1" stroke="none"/>
      </g>
     </g>
    </svg>
    `,
    toggle_acars_only_show_acars: '<i class="fas fa-plane-slash"></i>',
    toggle_acars_only_show_all: '<i class="fas fa-plane"></i>',
    toggle_datablocks_on: '<i class="fas fa-comment-alt"></i>',
    toggle_datablocks_off: '<i class="far fa-comment-alt"></i>',
    toggle_extended_datablocks_on: '<i class="fas fa-toggle-on"></i>',
    toggle_extended_datablocks_off: '<i class="fas fa-toggle-off"></i>',
    mark_all_messages_read: '<i class="fas fa-check"></i>',
    toggle_unread_messages_on: '<i class="fas fa-square"></i>',
    toggle_unread_messages_off: '<i class="far fa-square"></i>',
    legend_has_acars: legend_has_acars_url,
    legend_has_acars_alert_unread: legend_has_acars_alert_unread_url,
    legend_has_acars_alert_read: legend_has_acars_alert_read_url,
    legend_with_acars_unread: legend_with_acars_unread_url,
    legend_without_acars_url: legend_without_acars_url,
    safari_image_url: safari_image_url,
};
export let misc_assets = {
    acars_alert: acars_alert_url,
};
