// Copyright (C) 2022-2024 Frederick Clausen II
// This file is part of acarshub <https://github.com/sdr-enthusiasts/docker-acarshub>.
// acarshub is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
// acarshub is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
// You should have received a copy of the GNU General Public License
// along with acarshub.  If not, see <http://www.gnu.org/licenses/>.
import * as LeafLet from "leaflet";
import "../js-other/SmoothWheelZoom";
import "../js-other/leaftlet.legend";
import "../js-other/Leaflet.Control.Custom";
import "../js-other/leaflet.radar";
import Cookies from "js-cookie";
import jBox from "jbox";
import { display_messages } from "../helpers/html_generator";
import { getBaseMarker, svgShapeToURI } from "../js-other/aircraft_icons";
import { resize_tabs, 
//showPlaneMessages,
find_matches, get_match, get_window_size,
//setScrollers,
 } from "../index";
import { tooltip } from "../helpers/tooltips";
import { images } from "../assets/assets";
export let live_map_page = {
    livemap_acars_path: "",
    livemap_acars_url: "",
    adsb_enabled: false,
    range_rings: true,
    live_map_page_active: false,
    adsb_planes: {},
    adsb_plane_tails: [],
    adsb_plane_hex: [],
    adsb_plane_callsign: [],
    had_targets: false,
    last_updated: 0,
    map: null,
    map_controls: null,
    legend: null,
    layerGroupPlanes: null,
    layerGroupPlaneDatablocks: null,
    layerGroupRangeRings: null,
    plane_markers: {},
    lat: 0,
    lon: 0,
    station_lat: 0,
    station_lon: 0,
    ignored_keys: ["trk", "alt", "call"],
    plane_message_modal: new jBox("Modal", {
        id: "set_modal",
        // width: 350,
        // height: 400,
        blockScroll: false,
        isolateScroll: true,
        animation: "zoomIn",
        draggable: "title",
        closeButton: "box",
        overlay: false,
        reposition: true,
        repositionOnOpen: false,
        title: "Messages",
        content: "",
        onClose: () => window.close_live_map_modal(),
    }),
    current_sort: Cookies.get("current_sort") || "callsign",
    ascending: true,
    window_size: null,
    show_only_acars: false,
    show_datablocks: false,
    show_extended_datablocks: false,
    show_unread_messages: true,
    show_nexrad: false,
    current_modal_terms: null,
    current_hovered_from_map: "",
    current_hovered_from_sidebar: "",
    modal_content: "",
    modal_current_tab: "",
    current_scale: Number(Cookies.get("live_map_zoom")) || 8,
    get_cookie_value: function () {
        this.show_only_acars = Cookies.get("only_acars") == "true" ? true : false;
        this.show_datablocks =
            Cookies.get("show_datablocks") == "true" ? true : false;
        this.show_extended_datablocks =
            Cookies.get("show_extended_datablocks") == "true" ? true : false;
        this.current_sort = Cookies.get("current_sort") || "callsign";
        this.ascending =
            !Cookies.get("sort_direction") || Cookies.get("sort_direction") == "true"
                ? true
                : false;
        this.show_unread_messages =
            !Cookies.get("show_unread_messages") ||
                Cookies.get("show_unread_messages") == "true"
                ? true
                : false;
        this.show_nexrad = Cookies.get("show_nexrad") == "true" ? true : false;
    },
    toggle_unread_messages: function () {
        this.show_unread_messages = !this.show_unread_messages;
        Cookies.set("show_unread_messages", String(this.show_unread_messages), {
            expires: 365,
            sameSite: "Strict",
        });
        this.redraw_map();
        this.set_controls();
    },
    toggle_acars_only: function () {
        this.show_only_acars = !this.show_only_acars;
        Cookies.set("only_acars", String(this.show_only_acars), {
            expires: 365,
            sameSite: "Strict",
        });
        if (this.live_map_page_active) {
            $("#toggle-acars").html(`${!this.show_only_acars
                ? images.toggle_acars_only_show_acars
                : images.toggle_acars_only_show_all}`);
            this.redraw_map();
        }
    },
    toggle_datablocks: function () {
        this.show_datablocks = !this.show_datablocks;
        Cookies.set("show_datablocks", String(this.show_datablocks), {
            expires: 365,
            sameSite: "Strict",
        });
        if (this.live_map_page_active) {
            $("#toggle-datablocks").html(`${this.show_datablocks
                ? images.toggle_datablocks_on
                : images.toggle_datablocks_off}`);
            this.redraw_map();
        }
    },
    toggle_extended_datablocks: function () {
        this.show_extended_datablocks = !this.show_extended_datablocks;
        Cookies.set("show_extended_datablocks", String(this.show_extended_datablocks), {
            expires: 365,
            sameSite: "Strict",
        });
        if (this.live_map_page_active) {
            $("#toggle-extended-datablocks").html(`${this.show_extended_datablocks
                ? images.toggle_extended_datablocks_on
                : images.toggle_extended_datablocks_off}`);
            this.redraw_map();
        }
    },
    toggle_nexrad: function () {
        this.show_nexrad = !this.show_nexrad;
        Cookies.set("show_nexrad", String(this.show_nexrad), {
            expires: 365,
            sameSite: "Strict",
        });
    },
    redraw_map: function () {
        if (this.live_map_page_active) {
            if (this.current_modal_terms != null) {
                this.showPlaneMessages(this.current_modal_terms.callsign, this.current_modal_terms.hex, this.current_modal_terms.tail);
            }
            this.update_targets();
            this.airplaneList();
            tooltip.attach_all_tooltips();
        }
    },
    set_targets: function (adsb_targets) {
        if (this.last_updated < adsb_targets.now) {
            this.last_updated = adsb_targets.now;
            this.adsb_plane_hex = [];
            this.adsb_plane_callsign = [];
            this.adsb_plane_tails = [];
            // Loop through all of the planes in the new data and save them to the target object
            adsb_targets.aircraft.forEach((aircraft) => {
                this.adsb_plane_hex.push(this.get_hex(aircraft));
                this.adsb_plane_callsign.push(this.get_callsign(aircraft));
                this.adsb_plane_tails.push(this.get_tail(aircraft));
                if (this.adsb_planes[aircraft.hex
                    .replace("-", "")
                    .replace(".", "")
                    .replace("~", "")
                    .toUpperCase()] == undefined) {
                    this.adsb_planes[aircraft.hex
                        .replace("-", "")
                        .replace(".", "")
                        .replace("~", "")
                        .toUpperCase()] = {
                        position: aircraft,
                        last_updated: this.last_updated,
                        id: aircraft.hex
                            .replace("-", "")
                            .replace(".", "")
                            .replace("~", "")
                            .toUpperCase(),
                        num_messages: 0,
                        position_marker: null,
                        datablock_marker: null,
                        icon: null,
                    };
                }
                else {
                    this.adsb_planes[aircraft.hex
                        .replace("-", "")
                        .replace(".", "")
                        .replace("~", "")
                        .toUpperCase()].position = aircraft;
                    this.adsb_planes[aircraft.hex
                        .replace("-", "")
                        .replace(".", "")
                        .replace("~", "")
                        .toUpperCase()].last_updated = this.last_updated;
                }
            });
            // Now loop through the target object and expire any that are no longer there
            for (let plane in this.adsb_planes) {
                if (this.adsb_planes[plane].last_updated < this.last_updated) {
                    if (this.adsb_planes[plane].position_marker != null &&
                        this.layerGroupPlanes.hasLayer(this.adsb_planes[plane].position_marker)) {
                        this.layerGroupPlanes.removeLayer(this.adsb_planes[plane].position_marker);
                    }
                    if (this.adsb_planes[plane].datablock_marker != null &&
                        this.layerGroupPlaneDatablocks.hasLayer(this.adsb_planes[plane].datablock_marker)) {
                        this.layerGroupPlaneDatablocks.removeLayer(this.adsb_planes[plane].datablock_marker);
                    }
                    delete this.adsb_planes[plane];
                }
            }
            if (!this.had_targets)
                this.mark_all_messages_read();
            if (this.live_map_page_active) {
                this.redraw_map();
            }
            this.had_targets = true;
        }
    },
    live_map: function (lat_in, lon_in, range_rings) {
        this.lat = lat_in;
        this.lon = lon_in;
        this.station_lat = this.lat;
        this.station_lon = this.lon;
        this.range_rings = range_rings;
        if (this.live_map_page_active && this.adsb_enabled) {
            this.map.setView([this.lat, this.lon]);
            this.set_range_markers();
        }
    },
    set_range_markers: function () {
        if (this.layerGroupRangeRings == null)
            this.layerGroupRangeRings = L.layerGroup();
        this.layerGroupRangeRings.clearLayers();
        if (!this.range_rings)
            return;
        const nautical_miles_to_meters = 1852;
        const ring_radius = [
            10 * nautical_miles_to_meters,
            50 * nautical_miles_to_meters,
            100 * nautical_miles_to_meters,
            150 * nautical_miles_to_meters,
            200 * nautical_miles_to_meters,
        ];
        ring_radius.forEach((radius) => {
            LeafLet.circle([this.station_lat, this.station_lon], {
                radius: radius,
                fill: false,
                interactive: false,
                weight: 1,
                color: "hsl(0, 0%, 0%)",
            }).addTo(this.layerGroupRangeRings);
        });
        this.map.addLayer(this.layerGroupRangeRings);
    },
    setSort: function (sort = "") {
        if (sort === "")
            return;
        if (sort === this.current_sort)
            this.ascending = !this.ascending;
        else if (sort === "msgs" || sort === "alerts")
            this.ascending = false;
        // two special cases where we want the default sort to be reversed
        else
            this.ascending = true;
        this.current_sort = sort;
        Cookies.set("current_sort", String(this.current_sort), {
            expires: 365,
            sameSite: "Strict",
        });
        Cookies.set("sort_direction", String(this.ascending), {
            expires: 365,
            sameSite: "Strict",
        });
        this.airplaneList();
    },
    match_plane: function (plane_data, callsign, tail, hex) {
        let num_messages = undefined;
        let alert = false;
        let num_alerts = undefined;
        if (num_messages == undefined && plane_data[hex.toUpperCase()]) {
            num_messages = plane_data[hex.toUpperCase()].count;
            alert = plane_data[hex.toUpperCase()].has_alerts;
            num_alerts = plane_data[hex.toUpperCase()].num_alerts;
        }
        if (num_messages == undefined && plane_data[callsign]) {
            num_messages = plane_data[callsign].count;
            alert = plane_data[callsign].has_alerts;
            num_alerts = plane_data[callsign].num_alerts;
        }
        if (num_messages == undefined && plane_data[callsign]) {
            num_messages = plane_data[callsign].count;
            alert = plane_data[callsign].has_alerts;
            num_alerts = plane_data[callsign].num_alerts;
        }
        if (num_messages == undefined && tail != undefined && plane_data[tail]) {
            num_messages = plane_data[tail].count;
            alert = plane_data[tail].has_alerts;
            num_alerts = plane_data[tail].num_alerts;
        }
        if (num_messages == undefined && tail != undefined && plane_data[tail]) {
            num_messages = plane_data[tail].count;
            alert = plane_data[tail].has_alerts;
            num_alerts = plane_data[tail].num_alerts;
        }
        return {
            num_messages: num_messages || 0,
            has_alerts: alert,
            num_alerts: num_alerts || 0,
        };
    },
    sort_list: function (plane_data) {
        return Object.values(this.adsb_planes).sort((a, b) => {
            const callsign_a = this.get_callsign(a.position);
            const callsign_b = this.get_callsign(b.position);
            const alt_a = this.get_alt(a.position);
            const alt_b = this.get_alt(b.position);
            const speed_a = this.get_speed(a.position);
            const speed_b = this.get_speed(b.position);
            const tail_a = this.get_tail(a.position);
            const tail_b = this.get_tail(b.position);
            const hex_a = this.get_hex(a.position);
            const hex_b = this.get_hex(b.position);
            const details_a = this.match_plane(plane_data, callsign_a, tail_a, hex_a);
            const details_b = this.match_plane(plane_data, callsign_b, tail_b, hex_b);
            const num_msgs_a = details_a.num_messages;
            const num_msgs_b = details_b.num_messages;
            const has_alerts_a = details_a.num_alerts;
            const has_alerts_b = details_b.num_alerts;
            if (this.current_sort === "alt") {
                if (alt_a == alt_b) {
                    if (callsign_a != callsign_b) {
                        if (callsign_a < callsign_b) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                }
                if (this.ascending) {
                    if (String(alt_a) == "GROUND" && String(alt_b) != "GROUND")
                        return -1;
                    else if (String(alt_b) == "ground" && String(alt_a) != "GROUND")
                        return 1;
                    return Number(alt_a) - Number(alt_b);
                }
                else {
                    if (String(alt_a) == "GROUND" && String(alt_b) != "GROUND")
                        return 1;
                    else if (String(alt_b) == "GROUND" && String(alt_a) != "GROUND")
                        return -1;
                    else if (alt_a == alt_b) {
                        if (callsign_a < callsign_b) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                    return Number(alt_b) - Number(alt_a);
                }
            }
            else if (this.current_sort === "alerts") {
                if (has_alerts_a == has_alerts_b) {
                    if (callsign_a != callsign_b) {
                        if (callsign_a < callsign_b) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                }
                if (this.ascending) {
                    return has_alerts_a - has_alerts_b;
                }
                else {
                    return has_alerts_b - has_alerts_a;
                }
            }
            else if (this.current_sort === "speed") {
                if (speed_a == speed_b) {
                    if (callsign_a != callsign_b) {
                        if (callsign_a < callsign_b) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                }
                if (this.ascending) {
                    return speed_a - speed_b;
                }
                else {
                    return speed_b - speed_a;
                }
            }
            else if (this.current_sort === "msgs") {
                if (num_msgs_a == num_msgs_b) {
                    if (callsign_a != callsign_b) {
                        if (callsign_a < callsign_b) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                }
                if (this.ascending) {
                    return num_msgs_a - num_msgs_b;
                }
                else {
                    return num_msgs_b - num_msgs_a;
                }
            }
            else {
                if (this.ascending) {
                    if (callsign_a < callsign_b) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                }
                else {
                    if (callsign_b < callsign_a) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                }
            }
        });
    },
    get_callsign: function (plane) {
        return (plane.flight && plane.flight.trim() !== ""
            ? plane.flight.trim()
            : plane.r && plane.r !== ""
                ? plane.r
                : plane.hex)
            .replace("~", "")
            .replace(".", "")
            .replace("-", "")
            .toUpperCase();
    },
    get_sqwk: function (plane) {
        return plane.squawk || 0;
    },
    get_alt: function (plane) {
        return plane.alt_baro ? String(plane.alt_baro).toUpperCase() : 0;
    },
    get_speed: function (plane) {
        return plane.gs ? plane.gs : 0;
    },
    get_tail: function (plane) {
        return plane.r
            ? plane.r.replace("-", "").replace(".", "").replace("~", "")
            : undefined;
    },
    get_hex: function (plane) {
        return plane.hex
            ? plane.hex
                .replace("-", "")
                .replace(".", "")
                .replace("~", "")
                .toUpperCase()
            : undefined;
    },
    get_baro_rate: function (plane) {
        return plane.baro_rate ? plane.baro_rate : 0;
    },
    get_heading: function (plane) {
        return plane.track || 0;
    },
    get_ac_type: function (plane) {
        return plane.t || undefined;
    },
    get_icon: function (plane) {
        return this.adsb_planes[plane].icon || undefined;
    },
    get_lat: function (plane) {
        return plane.lat || 0;
    },
    get_lon: function (plane) {
        return plane.lon || 0;
    },
    set_old_messages: function (plane, new_messages) {
        this.adsb_planes[plane].num_messages = new_messages;
    },
    get_old_messages: function (plane) {
        return this.adsb_planes[plane].num_messages;
    },
    get_current_planes: function () {
        return {
            callsigns: this.adsb_plane_callsign,
            hex: this.adsb_plane_hex,
            tail: this.adsb_plane_tails,
        };
    },
    airplaneList: function () {
        const plane_data = find_matches();
        let num_planes = 0;
        let num_planes_targets = 0;
        let sorted = this.sort_list(plane_data);
        let plane_callsigns = [];
        let acars_planes = 0;
        let acars_message_count = 0;
        const alt_width = 20;
        const alert_width = 15;
        const speed_width = 16;
        const msgs_width = 21;
        const callsign_width = 23;
        //const callsign_width = 100 - alt_width - code_width - speed_width - msgs_width;
        let html = "";
        // add data to the table
        for (const plane in sorted) {
            const current_plane = sorted[plane].position;
            num_planes++;
            if (current_plane.lat)
                num_planes_targets++;
            const alt = this.get_alt(current_plane);
            const speed = this.get_speed(current_plane);
            const squawk = this.get_sqwk(current_plane);
            const callsign = this.get_callsign(current_plane);
            const hex = this.get_hex(current_plane);
            if (hex === undefined) {
                console.error("NO HEX FOUND");
                continue;
            }
            plane_callsigns.push({ callsign: callsign, hex: hex });
            const tail = this.get_tail(current_plane);
            const baro_rate = this.get_baro_rate(current_plane);
            const details = this.match_plane(plane_data, callsign, tail, hex);
            const num_messages = details.num_messages;
            const num_alerts = details.num_alerts;
            const old_messages = this.get_old_messages(hex);
            let has_new_messages = false;
            if (num_messages) {
                acars_planes++;
                acars_message_count += num_messages;
                if (old_messages > num_messages) {
                    console.error("OLD MESSAGES WAS LARGER THAN TOTAL MESSAGE COUNT: " + callsign, hex, tail, num_messages, old_messages);
                    this.set_old_messages(hex, num_messages);
                }
                else if (num_messages !== old_messages) {
                    has_new_messages = true;
                }
            }
            if (!this.show_only_acars || num_messages) {
                let styles = "";
                if (this.current_hovered_from_map !== "" &&
                    this.current_hovered_from_map == callsign &&
                    callsign &&
                    num_messages) {
                    if (!num_alerts)
                        styles = " sidebar_hovered_from_map_acars";
                    else
                        styles = " sidebar_hovered_from_map_with_unread";
                }
                else if (this.current_hovered_from_map == callsign && !num_alerts) {
                    styles = " sidebar_hovered_from_map_no_acars";
                }
                else if (num_alerts && this.current_hovered_from_map == callsign) {
                    styles = has_new_messages
                        ? " sidebar_alert_unread_hovered_from_map"
                        : " sidebar_alert_unread_hovered_from_map";
                }
                else if (num_alerts) {
                    styles = has_new_messages
                        ? " sidebar_alert_unread"
                        : " sidebar_alert_read";
                }
                else if (callsign && num_messages && styles == "") {
                    if (!has_new_messages)
                        styles = " sidebar_no_hover_with_acars";
                    else
                        styles = " sidebar_no_hover_with_unread";
                }
                html += `<div id="${callsign}" class="plane_list${styles}">
        <div class="plane_element noleft" style="width:${callsign_width}%">${callsign && num_messages
                    ? `<a href="javascript:showPlaneMessages('${callsign}', '${hex}', '${tail}');">${callsign}</a>`
                    : callsign || "&nbsp;"}</div>
        <div class="plane_element" style="width: ${alt_width}%;">${alt || "&nbsp;"}${alt && alt !== "GROUND" && baro_rate > 100
                    ? '&nbsp;<i class="fas fa-arrow-up"></i>'
                    : ""}${alt && alt !== "GROUND" && baro_rate < -100
                    ? '&nbsp;<i class="fas fa-arrow-down"></i>'
                    : ""}</div>
        <div class="plane_element" style="width: ${speed_width}%;">${Math.round(speed) || "&nbsp;"}</div>
        <div class="plane_element" style="width: ${alert_width}%;">${num_alerts || "&nbsp;"}</div>
        <div class="plane_element" style="width: ${msgs_width}%;">${this.show_unread_messages && num_messages
                    ? num_messages - old_messages + " / "
                    : ""}${num_messages || "&nbsp;"}</div></div>`;
            }
        }
        html =
            `<div class="plane_list_no_hover" style="color: var(--blue-highlight) !important;background-color: var(--grey-bg)"><div class="plane_element noleft" id="num_planes" style="width: 50%"></div><div class="plane_element noleft" id="num_planes_targets" style="width: 50%"></div></div>
    <div class="plane_list_no_hover" style="color: var(--blue-highlight) !important;background-color: var(--grey-bg)"><div class="plane_element noleft" id="num_acars_planes" style="vertical-align: text-top;width: 100%">Planes with ACARS Msgs: ${acars_planes}<br>Total ACARS Messages: ${acars_message_count}</div></div>
    <div class="plane_list_no_hover" style="font-weight: bold;border-bottom: 1px solid black;color: var(--blue-highlight) !important;background-color: var(--grey-bg)">
    <div class="plane_element plane_header noleft" style="width: ${callsign_width}%"><a href="javascript:setSort('callsign')">Callsign</a></div>
    <div class="plane_element plane_header" style="width: ${alt_width}%;"><a href="javascript:setSort('alt')">Alt</a></div>
    <div class="plane_element plane_header" style="width: ${speed_width}%;"><a href="javascript:setSort('speed')">Speed</a></div>
    <div class="plane_element plane_header" style="width: ${alert_width}%;"><a href="javascript:setSort('alerts')">Alert</a></div>
    <div class="plane_element plane_header" style="width: ${msgs_width}%;"><a href="javascript:setSort('msgs')">Msgs</a></div></div>` +
                html;
        $("#planes").html(html);
        for (const id in plane_callsigns) {
            const plane_list = plane_callsigns[id].callsign;
            const hex_list = plane_callsigns[id].hex;
            if (plane_list && hex_list) {
                const current_plane = this.adsb_planes[hex_list] !== undefined &&
                    this.adsb_planes[hex_list].position !== undefined
                    ? this.adsb_planes[hex_list].position
                    : null;
                if (current_plane !== null &&
                    current_plane.lat != null &&
                    current_plane.lon != null) {
                    const callsign = this.get_callsign(current_plane);
                    if (callsign.includes("@@")) {
                        console.error("CALLSIGN CONTAINS @@. Not adding mouse hover handlers.");
                        continue;
                    }
                    const hex = this.get_hex(current_plane);
                    if (hex === undefined) {
                        console.error("NO HEX FOR PLANE");
                        continue;
                    }
                    const tail = this.get_tail(current_plane);
                    const details = this.match_plane(plane_data, callsign, tail, hex);
                    const num_messages = details.num_messages;
                    const old_messages = this.get_old_messages(hex);
                    const alert = details.has_alerts;
                    const squawk = this.get_sqwk(current_plane);
                    $(`#${callsign}`).on({
                        mouseenter: () => {
                            if (this.current_hovered_from_sidebar !== hex) {
                                this.current_hovered_from_sidebar = callsign;
                                $(`#${hex}_marker`).removeClass(this.find_plane_color(callsign, alert, num_messages, squawk, old_messages, hex, tail, true));
                                $(`#${hex}_marker`).addClass("airplane_orange");
                            }
                        },
                        mouseleave: () => {
                            this.current_hovered_from_sidebar = "";
                            $("div").removeClass("airplane_orange");
                            $(`#${hex}_marker`).addClass(this.find_plane_color(callsign, alert, num_messages, squawk, old_messages, hex, tail));
                            tooltip.attach_all_tooltips();
                        },
                    });
                }
            }
        }
        $("#num_planes").html(`Planes: ${num_planes}`);
        $("#num_planes_targets").html(`Planes w/ Targets: ${num_planes_targets}`);
    },
    metersperpixel: function () {
        return ((40075016.686 *
            Math.abs(Math.cos((this.map.getCenter().lat * Math.PI) / 180))) /
            Math.pow(2, this.map.getZoom() + 8));
    },
    offset_datablock: function (centerpoint) {
        const mtp = this.metersperpixel();
        const offset_y = 0;
        const offset_x = mtp * 30;
        //Earth’s radius, sphere
        const R = 6378137;
        //Coordinate offsets in radians
        const dLat = offset_y / R;
        const dLon = offset_x / (R * Math.cos((Math.PI * centerpoint[0]) / 180));
        return [
            centerpoint[0] + (dLat * 180) / Math.PI,
            centerpoint[1] + (dLon * 180) / Math.PI,
        ];
    },
    find_plane_color: function (callsign, alert, num_messages, squawk, old_messages, hex, tail, skip_hovered = false) {
        let color = "airplane_blue";
        if (!skip_hovered && this.current_hovered_from_sidebar == callsign)
            color = "airplane_orange";
        else if ((alert && this.show_unread_messages && num_messages !== old_messages) ||
            squawk == 7500 ||
            squawk == 7600 ||
            squawk == 7700)
            color = "airplane_red";
        else if (alert) {
            color = "airplane_brown";
        }
        else if (num_messages) {
            if (old_messages > num_messages) {
                console.error("OLD MESSAGES WAS LARGER THAN TOTAL MESSAGE COUNT: " + callsign, hex, tail, num_messages, old_messages);
                this.set_old_messages(hex, num_messages);
                num_messages = old_messages;
            }
            if (this.show_unread_messages && num_messages !== old_messages)
                color = "airplane_darkgreen";
            else
                color = "airplane_green";
        }
        return color;
    },
    update_targets: function () {
        if (typeof this.map !== null &&
            this.layerGroupPlanes !== null &&
            this.adsb_planes !== null) {
            // clear old planes
            // this.layerGroupPlanes.clearLayers();
            // this.layerGroupPlaneDatablocks.clearLayers();
            const plane_data = find_matches();
            for (const plane in this.adsb_planes) {
                if (this.adsb_planes[plane].position.lat != null &&
                    this.adsb_planes[plane].position.lon != null) {
                    const current_plane = this.adsb_planes[plane].position;
                    const callsign = this.get_callsign(current_plane);
                    if (callsign.includes("@@")) {
                        console.error("CALLSIGN CONTAINS @s. Skipping target.");
                        continue;
                    }
                    const rotate = this.get_heading(current_plane);
                    const alt = this.get_alt(current_plane);
                    const hex = this.get_hex(current_plane);
                    if (hex === undefined) {
                        console.error("NO HEX FOR PLANE");
                        continue;
                    }
                    const speed = this.get_speed(current_plane);
                    const squawk = this.get_sqwk(current_plane);
                    const baro_rate = this.get_baro_rate(current_plane);
                    const tail = this.get_tail(current_plane);
                    const ac_type = this.get_ac_type(current_plane);
                    const lon = this.get_lon(current_plane);
                    const lat = this.get_lat(current_plane);
                    let icon = this.get_icon(plane);
                    let num_messages = null;
                    const old_messages = this.get_old_messages(plane);
                    const details = this.match_plane(plane_data, callsign, tail, hex);
                    num_messages = details.num_messages;
                    const alert = details.has_alerts;
                    let color = this.find_plane_color(callsign, alert, num_messages, squawk, old_messages, hex, tail);
                    const popup_text = `<div>${callsign !== hex ? callsign + "/" : ""}${hex}<hr>Altitude: ${String(alt).toUpperCase()}${String(alt) !== "GROUND" ? " ft" : ""}
          ${baro_rate ? "<br>Altitude Rate: " + baro_rate + "fpm" : ""}<br>Heading: ${Math.round(rotate)}&deg;${speed ? "<br>Speed: " + Math.round(speed) + " knots" : ""}${squawk ? "<br>Squawk: " + squawk : ""}${tail ? "<br>Tail Number: " + tail : ""}${ac_type ? "<br>Aircraft Type: " + ac_type : ""}${num_messages ? "<br><br>ACARS messages: " + num_messages : ""}</div>`;
                    let datablock = `${callsign}`;
                    if (this.show_extended_datablocks) {
                        datablock += `<br>${alt}`;
                        if (ac_type || speed) {
                            datablock += `<br>${ac_type !== undefined ? ac_type + " " : ""}${speed !== undefined ? Math.round(speed) + " kts" : ""}`;
                        }
                        if (num_messages) {
                            datablock += `<br>Msgs: ${num_messages}`;
                        }
                    }
                    if (this.adsb_planes[plane].position_marker === null) {
                        if (icon == null) {
                            const type_shape = getBaseMarker(String(current_plane.category), current_plane.t, null, null, current_plane.type, alt);
                            icon = svgShapeToURI(type_shape.name, 0.5, type_shape.scale * 1.5);
                            this.adsb_planes[hex].icon = icon;
                        }
                        const marker_icon_text = `<div><div id="${hex}_marker" class="datablock ${color}" data-jbox-content="${popup_text}" style="-webkit-transform:rotate(${rotate}deg); -moz-transform: rotate(${rotate}deg); -ms-transform: rotate(${rotate}deg); -o-transform: rotate(${rotate}deg); transform: rotate(${rotate}deg);">${icon.svg}</div></div>`;
                        let plane_icon = LeafLet.divIcon({
                            className: "airplane",
                            html: marker_icon_text,
                            iconSize: [icon.width, icon.height],
                        });
                        let plane_marker = LeafLet.marker([lat, lon], {
                            icon: plane_icon,
                            riseOnHover: true,
                        });
                        this.adsb_planes[plane].position_marker = plane_marker;
                    }
                    // All fields generated. Time to determine if the marker needs to be displayed
                    if (!this.show_only_acars || num_messages) {
                        // Marker Should be displayed
                        if (!this.layerGroupPlanes.hasLayer(this.adsb_planes[plane].position_marker)) {
                            // Marker is missing, add it
                            this.adsb_planes[plane].position_marker.addTo(this.layerGroupPlanes);
                            if (num_messages) {
                                // Add in click event for showing messages
                                this.adsb_planes[plane].position_marker.on("click", () => {
                                    this.showPlaneMessages(callsign, hex, tail);
                                    const color = this.find_plane_color(callsign, alert, num_messages || 0, squawk, num_messages || 0, hex, tail);
                                    $(`#${hex}_marker`).removeClass();
                                    $(`#${hex}_marker`).addClass(`datablock ${color} data-jbox-content="${popup_text}`);
                                });
                            }
                            $(`#${hex}_marker`).on({
                                mouseenter: () => {
                                    if (this.current_hovered_from_map !== callsign) {
                                        this.current_hovered_from_map = callsign;
                                        this.airplaneList();
                                    }
                                },
                                mouseleave: () => {
                                    this.current_hovered_from_map = "";
                                    this.airplaneList();
                                },
                            });
                        }
                        else {
                            // Marker is present, update it
                            this.adsb_planes[plane].position_marker.setLatLng([lat, lon]);
                            $(`#${hex}_marker`).removeClass();
                            $(`#${hex}_marker`).removeAttr("style");
                            $(`#${hex}_marker`).removeAttr("data-jbox-content");
                            $(`#${hex}_marker`).addClass(`datablock ${color}`);
                            $(`#${hex}_marker`).attr("data-jbox-content", popup_text);
                            $(`#${hex}_marker`).css("-webkit-transform", `:rotate(${rotate}deg)`);
                            $(`#${hex}_marker`).css("-moz-transform", `rotate(${rotate}deg)`);
                            $(`#${hex}_marker`).css("-ms-transform", `rotate(${rotate}deg)`);
                            $(`#${hex}_marker`).css("-o-transform", `rotate(${rotate}deg)`);
                            $(`#${hex}_marker`).css("transform", `rotate(${rotate}deg)`);
                            if (num_messages) {
                                // Add in click event for showing messages
                                // If the plane previously didn't have messages when it was generated the click event is never added
                                // So we need to add it here
                                // But we'll hit a snag if the plane already had a click event, so we'll remove it first
                                this.adsb_planes[plane].position_marker.off("click");
                                this.adsb_planes[plane].position_marker.on("click", () => {
                                    this.showPlaneMessages(callsign, hex, tail);
                                    const color = this.find_plane_color(callsign, alert, num_messages || 0, squawk, num_messages || 0, hex, tail);
                                    $(`#${hex}_marker`).removeClass();
                                    $(`#${hex}_marker`).addClass(`datablock ${color} data-jbox-content="${popup_text}`);
                                });
                            }
                        }
                    }
                    else if (this.show_only_acars &&
                        this.layerGroupPlanes.hasLayer(this.adsb_planes[plane].position_marker)) {
                        // remove the marker if present
                        this.layerGroupPlanes.removeLayer(this.adsb_planes[plane].position_marker);
                    }
                    // Now determine if datablocks need to be displayed
                    if (this.adsb_planes[plane].datablock_marker === null) {
                        // datablock is missing, add it
                        let datablock_icon = new LeafLet.DivIcon({
                            className: "airplane",
                            html: `<div id="${hex}_datablock" class="airplane_datablock">` +
                                datablock +
                                "</div>",
                        });
                        this.adsb_planes[plane].datablock_marker = new LeafLet.Marker(this.offset_datablock([lat, lon]), { icon: datablock_icon });
                    }
                    // Datablock is present, update it
                    if (this.show_datablocks &&
                        (!this.show_only_acars || num_messages) &&
                        this.layerGroupPlaneDatablocks.hasLayer(this.adsb_planes[plane].datablock_marker)) {
                        this.adsb_planes[plane].datablock_marker.setLatLng(this.offset_datablock([lat, lon]));
                        $(`#${hex}_datablock`).html(datablock);
                    }
                    else if (this.show_datablocks &&
                        (!this.show_only_acars || num_messages)) {
                        // datablock is missing and should be displayed, add it to the map
                        this.adsb_planes[plane].datablock_marker.addTo(this.layerGroupPlaneDatablocks);
                    }
                    else if (this.layerGroupPlaneDatablocks.hasLayer(this.adsb_planes[plane].datablock_marker)) {
                        // datablock is present and should not be displayed, remove it from the map
                        this.layerGroupPlaneDatablocks.removeLayer(this.adsb_planes[plane].datablock_marker);
                    }
                }
                else {
                    // hide the plane marker if it exists
                    // I doubt we could ever end up in this branch but who knows
                    if (this.adsb_planes[plane].position_marker !== null &&
                        this.layerGroupPlanes.hasLayer(this.adsb_planes[plane].position_marker)) {
                        this.layerGroupPlanes.removeLayer(this.adsb_planes[plane].position_marker);
                    }
                    // hide the datablock if it exists
                    if (this.adsb_planes[plane].datablock_marker !== null &&
                        this.layerGroupPlaneDatablocks.hasLayer(this.adsb_planes[plane].datablock_marker)) {
                        this.layerGroupPlaneDatablocks.removeLayer(this.adsb_planes[plane].datablock_marker);
                    }
                }
            }
        }
    },
    mark_all_messages_read: function () {
        const plane_data = find_matches();
        for (const plane in this.adsb_planes) {
            const current_plane = this.adsb_planes[plane].position;
            const callsign = this.get_callsign(current_plane);
            const hex = this.get_hex(current_plane);
            if (hex === undefined) {
                console.error("NO HEX FOR PLANE");
                continue;
            }
            const tail = this.get_tail(current_plane);
            const details = this.match_plane(plane_data, callsign, tail, hex);
            const num_messages = details.num_messages;
            this.adsb_planes[plane].num_messages = num_messages;
        }
        this.redraw_map();
    },
    showPlaneMessages: function (plane_callsign = "", plane_hex = "", plane_tail = "") {
        if (plane_callsign === "" && plane_hex === "")
            return;
        this.current_modal_terms = {
            callsign: plane_callsign,
            hex: plane_hex,
            tail: plane_tail,
        };
        const plane_details = get_match(plane_callsign, plane_hex, plane_tail);
        const matches = plane_details.messages;
        if (matches.length === 0)
            return;
        if (this.modal_content == "") {
            this.modal_current_tab ==
                matches[matches.length - 1].uid + ";" + matches[0];
        }
        const html = '<div style="background:white">' +
            display_messages([matches], this.modal_current_tab, true) +
            "</div>";
        if (this.modal_content !== html) {
            this.modal_content = html;
            this.plane_message_modal.setContent(html);
        }
        this.plane_message_modal.setTitle(`Messages for ${plane_callsign}`);
        const window_size = get_window_size();
        const window_width = this.get_modal_width();
        const window_height = this.get_modal_height();
        this.plane_message_modal.setHeight(window_height);
        this.plane_message_modal.setWidth(window_width);
        this.plane_message_modal.open();
        this.adsb_planes[plane_hex].num_messages = matches.length;
        this.airplaneList();
        resize_tabs(window_width - 40, false);
        $(".show_when_small").css("display", `inline-block`);
        $(".show_when_big").css("display", "none");
        $(".dont_show").css("display", "none");
        tooltip.attach_all_tooltips();
    },
    close_live_map_modal: function () {
        this.current_modal_terms = null;
        this.modal_content = "";
    },
    handle_radio: function (element_id, uid) {
        this.modal_current_tab = uid + ";" + element_id;
        if (this.current_modal_terms != null) {
            this.showPlaneMessages(this.current_modal_terms.callsign, this.current_modal_terms.hex, this.current_modal_terms.tail);
        }
        tooltip.cycle_tooltip();
    },
    updateModalSize: function (new_window_size) {
        this.window_size = new_window_size;
        if (this.map) {
            this.map.invalidateSize();
        }
        const window_width = this.get_modal_width();
        const window_height = this.get_modal_height();
        this.plane_message_modal.setHeight(window_height);
        this.plane_message_modal.setWidth(window_width);
        resize_tabs((this.window_size.height > 500 ? this.window_size.height : 500) - 40, false);
        $(".show_when_small").css("display", `inline-block`);
        $(".show_when_big").css("display", "none");
        tooltip.attach_all_tooltips();
    },
    get_modal_height: function () {
        return this.window_size.height < 500 ? this.window_size.height : 500;
    },
    get_modal_width: function () {
        return this.window_size.width < 500 ? this.window_size.width : 500;
    },
    zoom_in: function () {
        this.map.setZoom(this.map.getZoom() + 1);
    },
    zoom_out: function () {
        this.map.setZoom(this.map.getZoom() - 1);
    },
    live_map_active: function (state = false, window_size) {
        this.live_map_page_active = state;
        this.window_size = window_size;
        this.get_cookie_value();
        let leafletRadarAttribution = '<a href="https://github.com/rwev/leaflet-radar">Radar</a>';
        if (this.live_map_page_active && this.adsb_enabled) {
            Object.keys(this.adsb_planes).forEach((plane) => {
                this.adsb_planes[plane].datablock_marker = null;
                this.adsb_planes[plane].position_marker = null;
            });
            this.set_html();
            this.map = LeafLet.map("mapid", {
                zoomDelta: 0.2,
                center: [this.lat, this.lon],
                zoom: this.current_scale,
                scrollWheelZoom: false,
                smoothWheelZoom: true,
                smoothSensitivity: 1,
                zoomControl: false,
                click: /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
                    ? false
                    : true,
                tap: /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
                    ? true
                    : false,
            });
            LeafLet.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                detectRetina: false,
                opacity: 0.6,
                attribution: [
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    leafletRadarAttribution,
                ].join(" | "),
            }).addTo(this.map);
            this.set_range_markers();
            LeafLet.control
                .custom({
                position: "topleft",
                content: '<button type="button" id="zoomin" class="btn btn-default bg-white" onclick="zoom_in()"><i class="fas fa-plus"></i></button>' +
                    '<button type="button" id="zoomout" class="btn btn-default bg-white" onclick="zoom_out()"><i class="fas fa-minus"></i></button>',
                classes: "btn-group-vertical btn-group-sm",
                style: {
                    margin: "10px",
                    padding: "0px 0 0 0",
                    cursor: "pointer",
                },
                events: {},
            })
                .addTo(this.map);
            this.layerGroupPlanes = LeafLet.layerGroup().addTo(this.map);
            this.layerGroupPlaneDatablocks = LeafLet.layerGroup().addTo(this.map);
            LeafLet.control
                .radar({ position: "bottomleft", start_active: this.show_nexrad })
                .addTo(this.map);
            this.set_controls();
            this.map.on({
                zoom: () => {
                    this.current_scale = this.map.getZoom();
                    Cookies.set("live_map_zoom", String(this.current_scale), {
                        expires: 365,
                        sameSite: "Strict",
                    });
                },
                move: () => {
                    const center = this.map.getCenter();
                    this.lat = center.lat;
                    this.lon = center.lng;
                },
            });
            this.redraw_map();
        }
        tooltip.cycle_tooltip();
    },
    set_controls: function () {
        if (this.legend)
            this.legend.remove();
        this.legend = LeafLet.control.Legend({
            position: "bottomleft",
            symbolWidth: 45,
            symbolHeight: 45,
            opacity: 0.6,
            collapsed: true,
            legends: [
                {
                    label: "Planes With ACARS Messages",
                    type: "image",
                    url: images.legend_has_acars,
                },
                {
                    label: "Planes With Unread ACARS Messages",
                    type: "image",
                    url: images.legend_with_acars_unread,
                },
                {
                    label: "Planes With Unread ACARS Alerts",
                    type: "image",
                    url: images.legend_has_acars_alert_unread,
                },
                {
                    label: "Planes With Read ACARS Alerts",
                    type: "image",
                    url: images.legend_has_acars_alert_read,
                },
                {
                    label: "Planes Without ACARS Messages",
                    type: "image",
                    url: images.legend_without_acars_url,
                },
            ],
        });
        this.legend.addTo(this.map);
        if (this.map_controls)
            this.map_controls.remove();
        this.map_controls = LeafLet.control.custom({
            position: "topright",
            content: '<button type="button" id="toggle-acars" class="btn btn-default toggle-acars bg-white" onclick="toggle_acars_only()">' +
                `    ${!this.show_only_acars
                    ? images.toggle_acars_only_show_acars
                    : images.toggle_acars_only_show_all}` +
                "</button>" +
                '<button type="button" id="toggle-datablocks" class="btn btn-info toggle-datablocks" onclick="toggle_datablocks()">' +
                `    ${this.show_datablocks
                    ? images.toggle_datablocks_on
                    : images.toggle_datablocks_off}` +
                "</button>" +
                '<button type="button" id="toggle-extended-datablocks" class="btn btn-primary toggle-extended-datablocks" onclick="toggle_extended_datablocks()">' +
                `    ${this.show_extended_datablocks
                    ? images.toggle_extended_datablocks_on
                    : images.toggle_extended_datablocks_off}` +
                "</button>" +
                '<button type="button" class="btn btn-success toggle-unread-messages" onclick="toggle_unread_messages()">' +
                `    ${images.toggle_unread_messages_on}` +
                "</button>" +
                (this.show_unread_messages
                    ? `<button type="button" class="btn btn-danger mark-all-messages-read" onclick="mark_all_messages_read()">    ${images.mark_all_messages_read}</button>`
                    : ""),
            //+
            // '<button type="button" class="btn btn-warning">'+
            // '    <i class="fa fa-exclamation-triangle"></i>'+
            // '</button>',
            classes: "btn-group-vertical btn-group-sm",
            style: {
                margin: "10px",
                padding: "0px 0 0 0",
                cursor: "pointer",
            },
            events: {},
        });
        this.map_controls.addTo(this.map);
    },
    set_live_map_page_urls: function (documentPath, documentUrl) {
        this.livemap_acars_path = documentPath;
        this.livemap_acars_url = documentUrl;
    },
    set_html: function () {
        $("#modal_text").html("");
        $("#page_name").html("");
        if (this.adsb_enabled)
            $("#log").html('<div style="display: flex;height: 100%;" ><div id="mapid"></div><div id="planes"></div>');
        else
            $("#log").html("ADSB Disabled");
        //setScrollers();
    },
    destroy_maps: function () {
        this.map = null;
        this.layerGroupPlanes = null;
        this.layerGroupPlaneDatablocks = null;
        this.layerGroupRangeRings = null;
        this.adsb_planes = {};
        this.map_controls = null;
        this.legend = null;
        if (this.live_map_page_active)
            this.set_html();
    },
    is_adsb_enabled: function (is_enabled = false, window_size) {
        this.adsb_enabled = is_enabled;
        if (this.live_map_page_active) {
            this.set_html();
            if (this.live_map_page_active) {
                this.live_map_active(true, window_size);
            }
        }
    },
};
